
import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [{
    path: '/setting',
    name: 'setting',
    meta: { title: '设置' },
    redirect: '/setting/platform/config',
    component: Main,
    children: [
        {
            path: '/setting/platform/config',
            name: 'platform',
            meta: {
                title: '平台设置',
                parentPath: '/setting',
                icon: 'icon-pingtaishezhi'
            },
            component: Blank,
            redirect: '/setting/platform/config',
            children: [{
                path: '/setting/platform/config',
                name: 'config',
                meta: {
                    title: '平台信息',
                    parentPath: '/setting',
                    permission: ['view']
                },
                component: () => import('@/views/setting/platform/config.vue')
            }, {
                path: '/setting/platform/keep_on_record',
                name: 'keep_on_record',
                meta: {
                    title: '备案信息',
                    parentPath: '/setting',
                    permission: ['view']
                },
                component: () => import('@/views/setting/platform/keep_on_record.vue')
            }, {
                path: '/setting/platform/rate',
                name: 'keep_on_rate',
                meta: {
                    title: '汇率设置',
                    parentPath: '/setting',
                    permission: ['view']
                },
                component: () => import('@/views/setting/platform/rate.vue')
            }]
        }, {
            path: '/setting/service/service',
            name: 'service',
            meta: {
                title: '客服设置',
                parentPath: '/setting',
                icon: 'icon-kefushezhi'
            },
            component: Blank,
            redirect: '/setting/service/service',
            children: [{
                path: '/setting/service/service',
                name: 'config',
                meta: {
                    title: '联系客服',
                    parentPath: '/setting',
                    permission: ['view']
                },
                component: () => import('@/views/setting/service/service.vue')
            }]
        }, {
            path: '/setting/security/account',
            name: 'security',
            meta: {
                title: '安全设置',
                parentPath: '/setting',
                icon: 'icon-kefushezhi'
            },
            component: Blank,
            redirect: '/setting/security/account',
            children: [{
                path: '/setting/security/account',
                name: 'journal',
                meta: {
                    title: '修改密码',
                    parentPath: '/setting',
                    permission: ['view']
                },
                component: () => import('@/views/setting/security/account.vue')
            }]
        }, {
            path: '/setting/system_maintain/journal',
            name: 'system_maintain',
            meta: {
                title: '系统维护',
                parentPath: '/setting',
                icon: 'icon-xitongweihu'
            },
            component: Blank,
            redirect: '/setting/system_maintain/journal',
            children: [{
                path: '/setting/system_maintain/journal',
                name: 'journal',
                meta: {
                    title: '系统日志',
                    parentPath: '/setting',
                    permission: ['view']
                },
                component: () => import('@/views/setting/system_maintain/journal.vue')
            }, {
                path: '/setting/system_maintain/cache',
                name: 'cache',
                meta: {
                    title: '系统缓存',
                    parentPath: '/setting',
                    permission: ['view']
                },
                component: () => import('@/views/setting/system_maintain/cache.vue')
            }, {
                path: '/setting/system_maintain/updata',
                name: 'updata',
                meta: {
                    title: '系统升级',
                    // hidden: true,
                    parentPath: '/setting',
                    permission: ['view']
                },
                component: () => import('@/views/setting/system_maintain/updata.vue')
            }, {
                path: '/setting/system_maintain/environment',
                name: 'environment',
                meta: {
                    title: '系统环境',
                    // hidden: true,
                    parentPath: '/setting',
                    permission: ['view']
                },
                component: () => import('@/views/setting/system_maintain/environment.vue')
            }, {
                path: '/setting/system_maintain/task',
                name: 'task',
                meta: {
                    title: '定时任务',
                    parentPath: '/setting',
                    permission: ['view']
                },
                component: () => import('@/views/setting/system_maintain/task/lists.vue')
            }, {
                path: '/setting/system_maintain/task_edit',
                name: 'task_edit',
                meta: {
                    hidden: true,
                    title: '定时任务',
                    parentPath: '/setting',
                    permission: ['view']
                },
                component: () => import('@/views/setting/system_maintain/task/edit.vue')
            }]
        }, {
            path: '/setting/storage/storage',
            name: 'order',
            meta: {
                title: '存储设置',
                parentPath: '/setting',
                icon: 'icon-taocanguanli'
            },
            component: Blank,
            redirect: '/setting/storage/storage',
            children: [{
                path: '/setting/storage/storage',
                name: 'setting_storage',
                meta: {
                    title: '设置A',
                    parentPath: '/setting',
                    hidden: true
                },
                component: () => import('@/views/setting/storage/storage.vue')
            }, {
                path: '/setting/storage/index',
                name: 'setting_storage_index',
                meta: {
                    title: '存储设置',
                    parentPath: '/setting',
                    permission: ['view']

                },
                component: () => import('@/views/setting/storage/index.vue')
            }]
        },
        {
            path: '/setting/site/department',
            name: 'setting_site_department',
            meta: {
                title: '刊登',
                parentPath: '/setting',
                icon: 'icon-taocanguanli'
            },
            component: Blank,
            redirect: '/setting/site/department',
            children: [{
                path: '/setting/site/department',
                name: 'setting_site_department',
                meta: {
                    title: '站点列表',
                    parentPath: '/setting',
                    hidden: false
                },
                component: () => import('@/views/setting/site/site_list.vue')
            },{
                path: '/setting/site/site_edit',
                name: 'setting_site_site_edit',
                meta: {
                    title: '编辑站点',
                    parentPath: '/setting',
                    hidden: true
                },
                component: () => import('@/views/setting/site/site_edit.vue')
            },{
                path: '/setting/site/site_template',
                name: 'setting_site_site_template',
                meta: {
                    title: '模板管理',
                    parentPath: '/setting',
                    hidden: true
                },
                component: () => import('@/views/setting/site/site_template.vue')
            },{
                path: '/setting/site/site_template_edit',
                name: 'setting_site_site_template_edit',
                meta: {
                    title: '编辑模板',
                    parentPath: '/setting',
                    hidden: true
                },
                component: () => import('@/views/setting/site/site_template_edit.vue')
            }]
        },
        {
            path: '/setting/pay_manage',
            name: 'set_pay_manage',
            meta: {
                title: '支付设置',
                parentPath: '/setting',
                icon: 'icon-taocanguanli',
                permission: ['view']
            },
            component: Blank,
            redirect: '/setting/pay_info',
            children: [
                {
                    path: '/setting/pay_info',
                    name: 'pay_info',
                    meta: {
                        title: '支付设置',
                        parentPath: '/setting',
                        permission: ['view']
                    },
                    component: () => import('@/views/setting/pay/pay_info.vue')
                }
            ]
        },
        {
            path: '/setting/sms_setting',
            name: 'set_sms_setting',
            meta: {
                title: '短信设置',
                parentPath: '/setting',
                icon: 'icon-taocanguanli',
                permission: ['view']
            },
            component: Blank,
            redirect: '/setting/sms_info',
            children: [
                {
                    path: '/setting/sms_info',
                    name: 'sms_info',
                    meta: {
                        title: '短信设置',
                        parentPath: '/setting',
                        permission: ['view']
                    },
                    component: () => import('@/views/setting/sms/sms.vue')
                }
            ]
        },
        {
            path: '/setting/baidu_setting',
            name: 'set_baidu_setting',
            meta: {
                title: 'AI设置',
                parentPath: '/setting',
                icon: 'icon-taocanguanli',
                permission: ['view']
            },
            component: Blank,
            redirect: '/setting/platform/baidu',
            children: [
                {
                    path: '/setting/platform/baidu',
                    name: 'baidu',
                    meta: {
                        title: '百度 AI 设置',
                        parentPath: '/setting',
                        permission: ['view']
                    },
                    component: () => import('@/views/setting/platform/baidu.vue')
                },
                {
                    path: '/setting/platform/zuoTang',
                    name: 'zuoTang',
                    meta: {
                        title: '左糖 AI 设置',
                        parentPath: '/setting',
                        permission: ['view']
                    },
                    component: () => import('@/views/setting/platform/zuoTang.vue')
                },
                {
                    path: '/setting/platform/tkl',
                    name: 'tkl',
                    meta: {
                        title: '图可丽 AI 设置',
                        parentPath: '/setting',
                        permission: ['view']
                    },
                    component: () => import('@/views/setting/platform/tkl.vue')
                }
            ]
        },
        {
            path: '/setting/merchant_image_set',
            name: 'merchant_image_set',
            meta: {
                title: '图片配置',
                parentPath: '/setting',
                icon: 'icon-taocanguanli',
                permission: ['view']
            },
            component: Blank,
            redirect: '/setting/platform/baidu',
            children: [
                {
                    path: '/setting/platform/merchant_image_set',
                    name: 'baidu',
                    meta: {
                        title: '商户AI图片配置',
                        parentPath: '/setting',
                        permission: ['view']
                    },
                    component: () => import('@/views/setting/platform/merchant_image_set.vue')
                }
            ]
        },
        // {
        //   path: '/setting/money_setting',
        //   name: 'set_money_setting',
        //   meta: {
        //     title: '货币配置',
        //     parentPath: '/setting',
        //     icon: 'icon-taocanguanli',
        //     permission: ['view']
        //   },
        //   component: Blank,
        //   redirect: '/setting/money',
        //   children: [
        //     {
        //       path: '/setting/money',
        //       name: 'money',
        //       meta: {
        //         title: '货币配置',
        //         parentPath: '/setting',
        //         permission: ['view']
        //       },
        //       component: () => import('@/views/setting/money/money.vue')
        //     }
        //   ]
        // }
    ]
}]

export default routes
